import React,{Suspense} from 'react'
import {BrowserRouter, Redirect, Route, Router, Switch} from 'react-router-dom';
import routes from "../routes";
import {createBrowserHistory} from "history";


const loading = function () {
    return <div>loading...</div>
}
const browserHistory = createBrowserHistory();
export const Navigation = () =>{
    return (
        <Suspense fallback={loading()}>
            <Router history={browserHistory}>
                <BrowserRouter>
                    {routes.map((route, idx) => {
                        return route.component ? (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={props => (
                                    <route.component {...props} />
                                )} />
                        ) : (null);
                    })}
                </BrowserRouter>
            </Router>
        </Suspense>
    )
}
