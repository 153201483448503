import {NewsCard} from "./NewsCard";
import React, {useState} from "react";
import {useTranslation} from "bsma/src/lb-core/i18n/i18n";
import _ from "lodash";
import Moment from "moment";
import {Link} from "react-router-dom";

export const NewsComponent = ({data})=>{
  const { t, i18n } = useTranslation();
  document.title = t("NEWS");
  return  (
    <section className="news-cards" style={{marginTop:'30px'}}>
      <h3 className="title">{t("NEWS")}</h3>
      <div className="content">
        {
          _.map(data, (value,index)=>{
            return  (
                <NewsCard
                  key={index}
                  date={Moment(value.create_date).format("DD MM yyyy").toString().split(' ').map((v,index) => '<span key='+index+'>'+v+'</span>').join(' ')}
                  title={ <Link to={`/news/${value.id}`} > {value.title}</Link>}
                  text={ <div dangerouslySetInnerHTML={{__html: value.short_content}}/>}
                  img={value.image === ""? '/static/news_default.png':value.image}
                />
            )
          })
        }
      </div>
    </section>
  )
}
