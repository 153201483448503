import {PhotoCard} from "./PhotoCard";
import React, {Component, useCallback, useEffect, useState} from 'react';
import {useTranslation} from "bsma/src/lb-core/i18n/i18n";
import _ from "lodash";
import Moment from "moment";
import {useSelector} from "react-redux";



export const PhotoComponent = ({data})=>{
  const { t, i18n } = useTranslation();


  return  (
    <section className="photo" style={{marginTop:'30px'}}>
      <h3 className="title">{t("PHOTO")}</h3>
      <div className="content" call="3">
        {
          _.map(data, (value,index)=>{
            return  (
              <PhotoCard
                key = {index}
                date = {Moment(value.create_date).format("DD.MM.YYYY")}
                title = {value.title}
                img= {JSON.parse(value.image).path}
                id={value.id}
              />
            )
          })
        }
      </div>

    </section>
  )
}
