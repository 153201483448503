export const Config ={
   User:{
      Auth: "/api/login",
      Ping: "/api/secured/user"
   },
  Media:{
     Upload: "/api/secured/files/upload",
     GetFiles:"/api/secured/files/get"
  },
  Blog:{
     get: "/api/?app=blog/Blog&resource=list",
     Delete:"/api/?app=blog/Blog&resource=delete",
     Add: "/api/?app=blog/Blog&resource=add",
     Publish:"/api/?app=blog/Blog&resource=status",
     Update: "/api/?app=blog/Blog&resource=edit"
  },
  PodCast:{
     get: "/api/?app=podcast/Podcast&resource=list",
     Delete:"/api/?app=podcast/Podcast&resource=delete",
     Add: "/api/?app=podcast/Podcast&resource=add",
     Publish:"/api/?app=podcast/Podcast&resource=status",
     Update: "/api/?app=podcast/Podcast&resource=edit"
  },
  Artists:{
     get: "/api/?app=artist/Artist&resource=list",
     Delete:"/api/?app=artist/Artist&resource=delete",
     Add: "/api/?app=artist/Artist&resource=add",
     Publish:"/api/?app=artist/Artist&resource=status",
     Update: "/api/?app=artist/Artist&resource=edit"
  },
  Labels:{
     get: "/api/?app=labels/Labels&resource=list",
     Delete:"/api/?app=labels/Labels&resource=delete",
     Add: "/api/?app=labels/Labels&resource=add",
     Publish:"/api/?app=labels/Labels&resource=status",
     Update: "/api/?app=labels/Labels&resource=edit"
  },
  Web:{
     HomePageCarousel: "/webApi/plugin/data/",
     BannerComponent: "/webApi/plugin/data/",
     News: "/webApi/plugin/news/",
     Video: "/webApi/plugin/gallery/video/",
     Photo: "/webApi/plugin/gallery/image/",
     Gallery: "/webApi/plugin/gallery/image/",
     project:"/webApi/plugin/projects/",
     Menu: "/webApi/plugin/data/",
     Page: "/webApi/plugin/page/",
     Faq: "/webApi/plugin/data/",
     MainNews: "/webApi/plugin/news/main/",
     Contact: "/webApi/plugin/data/"
  }
}
