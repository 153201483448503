import React, {Component, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';
import {useSelector} from "react-redux";

export const VideoCard = (props)=>{

  return (

    <>
      <div className="callBox">
        <div className="img">
          <iframe width="100%" height="100%" src={props.video} frameBorder="0" allowFullScreen=""></iframe>
        </div>
        <p data-date="date">{props.date}</p>
        <p data-date="title">{props.title}</p>
      </div>
    </>
  )
}

VideoCard.propTypes = {
  date: PropTypes.string,
  title: PropTypes.string,
  video: PropTypes.string
}
VideoCard.defaultProps={
  date: "7 ოქტომბერი 2019 წელი",
  title:"მითითებული პირადი ნომერი უკვე არის სისტემაში ებული პირა",
  video: 'assets/img/projects.jpg'
}

