import React, {useCallback, useEffect, useState} from 'react';
import {
  LightgalleryProvider,
  LightgalleryItem, useLightgallery,
} from "react-lightgallery";
import Event from "../utils/eventLisener";


const PhotoItem = ({ image, thumb, group }) => {
  return (
    <div style={{maxWidth: "250px", width: "200px", padding: "5px", display:'none'}}>
      <LightgalleryItem group={group} src={image} thumb={thumb}>
        <img src={image} style={{width: "100%"}}/>
      </LightgalleryItem>
    </div>
    )
};



export const Lightgallery = ()=>{
  const [visible, setVisible] = useState(true);
  const [data, setData] = useState([]);
  useEffect(()=>{
    Event.subscribe('getGalleryData',e=>{
      setData([])
      setData([...e])
    })
    return ()=>{
      Event.unsubscribe('getGalleryData',e=>setData([]))
      //Event.unsubscribe('openGallery',e=>console.log('galleryUnsubscribe'))
    }
  },[data])




  useEffect(()=>{

    if(data.length > 0){
      Event.dispatch('openGallery',true);
    }
  },[data])

  const MySuperButton = ({ group_name }) => {
    const { openGallery } = useLightgallery();
    const open = useCallback(() => {
      openGallery(group_name); // you must to define target group, index (second parameter) is optional
    }, [group_name]);
    if(Event.events['openGallery'] === null || Event.events['openGallery'] === undefined){
      Event.subscribe('openGallery',e=>open())
    }

    return null;
  }

  return (
    <div>
      {visible ? (
        <LightgalleryProvider
          //onBeforeOpen={() => console.info("onBeforeOpen")}
          //onAfterOpen={() => console.info("onAfterOpen")}
          //onSlideItemLoad={() => console.info("onSlideItemLoad")}
          //onBeforeSlide={() => console.info("onBeforeSlide")}
          //onAfterSlide={() => console.info("onAfterSlide")}
          //onBeforePrevSlide={() => console.info("onBeforePrevSlide")}
          //onBeforeNextSlide={() => console.info("onBeforeNextSlide")}
          //onDragstart={() => console.info("onDragstart")}
          //onDragmove={() => console.info("onDragmove")}
          //onDragend={() => console.info("onDragend")}
          //onSlideClick={() => console.info("onSlideClick")}
          //onBeforeClose={() => console.info("onBeforeClose")}
          //onCloseAfter={() => console.info("onCloseAfter")}
        >

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {data.map((p, idx) => (
              <PhotoItem  key={idx} image={p} group='zura' />
            ))}
          </div>
          <MySuperButton group_name='zura' />
        </LightgalleryProvider>
      ) : null}
    </div>
  );

 // count={} onChangePage={} page={} rowsPerPage={}

}


