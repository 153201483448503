import React from  'react';
import PropTypes from 'prop-types';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import slImg from '../assets/img/ss.jpg';

export const Slider = (props)=>{

    console.log('type',props)

    return (
        <>
            <div className="lang">
                <a className="active">ENG</a>
                <a>ქარ</a>
            </div>

            <div className="slider" type={props.type}>

                <OwlCarousel
                    className="slider-carousel"
                    items = {1}
                    nav
                    dots
                    loop
                    margin = {0}
                    autoplay
                    autoplayTimeout = {6000}
                    autoplayHoverPause
                >
                    <div className="item" style={{background:'url('+slImg+')'}}>
                        <div className="owl_text">წარმატება იწყება აქ!<br/>აირჩიე საზღვაო განატლება</div>
                    </div>
                    <div className="item" style={{background:'url('+slImg+')'}}>
                        <div className="owl_text">წარმატება იწყება აქ!<br/>აირჩიე საზღვაო განატლება</div>
                    </div>

                </OwlCarousel>
            </div>
        </>

    )
}

Slider.propTypes = {
    type: PropTypes.string
}
Slider.defaultProps={
    type: "small",
}
