import React, {Suspense, useContext, useEffect, useState} from 'react';
import './assets/styles/global.scss';
import './assets/css/style.scss';
import {ThemeContext, Themes,Actions} from "bsma/src";
import {useDispatch, useSelector} from "react-redux";
import {Footer, Header, Lightgallery, ThemeSwitcher} from "./components";
import {Navigation} from "./components/Router";
import {Index} from "./views";
import "lightgallery.js/dist/css/lightgallery.css";
//import Snowfall from 'react-snowfall'

const  App = ()=> {
    const theme = useSelector(state=>state.Theme);
    const [height,setHeight] = useState(window.innerHeight)
    useEffect(() => {
            setTimeout(()=>{
                setHeight(document.body.clientHeight)
            },3000)
    }, []);



  return (
      <Suspense fallback="loading">
          {/*<Snowfall style={{zIndex:11, height: height}} />*/}


          <ThemeContext.Provider value={Themes[theme]}>
              <div className="App">
                    <Index>
                      <Navigation/>
                    </Index>
                <Lightgallery />
              </div>
          </ThemeContext.Provider>
      </Suspense>


  );
}

export default App;



