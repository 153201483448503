import { combineReducers } from 'redux';
import {CHANGE_THEME,CHANGE_LANGUAGE} from "../actionTypes";
const INITIAL_STATE = {};
const Store = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "test":
            return action.payload;
        default:
            return state
    }
};

const Theme = (state="dark",action )=>{
    switch (action.type){
        case CHANGE_THEME:
            return action.payload;
        default:
            return  state;
    }
}
const Lang = (state=localStorage.getItem("i18nextLng") || "ka",  action )=>{
  switch (action.type){
    case CHANGE_LANGUAGE:
      console.log("change")
      return action.payload;
    default:
      return  state;
  }}

const root = combineReducers({
    Store,
    Theme,
    Lang
});

export default root;
