import React, {useEffect, useState} from 'react';
import {Request,Config } from 'bsma/src';
import OwlCarousel from "react-owl-carousel";
import _ from 'lodash';
import i18n from "bsma/src/lb-core/i18n/i18n";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";
import {Slider} from "./Slider";

export const MainSlider = ({id, lang,type})=>{

  const [data, setData] = useState([]);
  const [title,setTitle] = useState("")
  const dispatch = useDispatch();


  if(['/','/home'].indexOf(window.location.pathname) !== -1){
    type = "main"
  }


  useEffect(()=>{
    Request.get(Config.Web.HomePageCarousel+lang+"/"+id).then(response=>{
      if(response['status']){
        setData(JSON.parse(response['data']['data']['data']))
        setTitle(response['data']['data']['title'])
      }else{
        setData([]);
        setTitle("");
      }
    })
  },[lang])

  const  onChangeLanguage=(lang)=> {
    i18n.changeLanguage(lang,(e)=>{
        dispatch({
          type:"CHANGE_LANGUAGE",
          payload:lang
        })
        setTimeout(()=>{
          window.location.reload()
        },10)
    })
  }

  return (
    <>
      <div className="lang" >
        <div className="lang-box">
          <a className={`${lang==='en'? 'active': ''}`} onClick={()=>onChangeLanguage('en')}>ENG</a>
          <a className={`${lang==='ka'? 'active': ''}`} onClick={()=>onChangeLanguage('ka')}>ქარ</a>
        </div>
      </div>
      <div className="slider"  type={type}>
        <a className="logo" href="/"></a>
        <OwlCarousel
          className="slider-carousel"
          items = {1}
          nav
          dots
          loop
          margin = {0}
          autoplay
          autoplayTimeout = {6000}
          autoplayHoverPause
        >
          {
            _.map(data,(value,index)=>{
              return  (
                <div className="item" key={index} style={{background:'url('+value.image+')'}}>
                  <div className="pattern"></div>
                  <div className="owl_text" dangerouslySetInnerHTML={{__html: value.title}}/>
                </div>
              )
            })
          }
        </OwlCarousel>
      </div>
    </>
  )

}

MainSlider.propTypes = {
  type: PropTypes.string
}
MainSlider.defaultProps={
  type: "small",
}
