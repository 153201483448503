import React, {useEffect, useState} from 'react';

import Pagination  from '@material-ui/lab/Pagination';
import PropTypes from "prop-types";

export const PaginationUI = ({onChange,count})=>{
  return (
    <Pagination onChange={(_, page) => onChange(page)} count={count} shape="rounded" />
  );

 // count={} onChangePage={} page={} rowsPerPage={}

}


PaginationUI.propTypes = {
  count: PropTypes.number.isRequired
}
