import React, {useCallback, useEffect, useState} from 'react';
import {Config, Request} from "bsma/src";
import _ from 'lodash';
import Event from "../utils/eventLisener";
export const Menu = ({title,test,onClickHeader, lang,id})=>{
  const [data, setData] = useState([]);
  const [open,setOpen] = useState(0)
  useEffect(()=>{
    Request.get(Config.Web.Menu+lang+"/"+id).then(response=>{
      if(response['status']){
        setData(JSON.parse(response['data']['data']['data']))
      }else{
        setData([]);
      }
    })
    Event.subscribe('openMenu',setOpen)
    return ()=>{
      Event.unsubscribe('openMenu',e=>console.log("unsubscribe"))

    }
  },[lang])


    return (
        <>
          {
            open===1?
              // mobile mode
              <div className="mobMenu" data-mobMode={open} >
                <div className="empty"></div>
                <div className="navbox">
                  <div className="close_mm" onClick={()=>setOpen(0)}>&#10006;</div>
                  <ul className="nav">
                    {
                      _.map(data, (value,key)=>{
                        return (
                          <li key={key}>
                            <a href={value.link !== ""?value.link:'#'}>
                              <span>{value.title}</span>
                              {
                                value.children.length>0?<i className="arrow"/>:''
                              }
                            </a>
                            {
                              (value.children.length>0)?
                                <div className="sub">
                                  <ul className="subnav">
                                    {
                                      _.map(value.children, (val,index)=>{

                                        return <li key={index}><a href={val.link}>
                                          <span>{val.title}</span>
                                          {
                                            val.children.length>0?<i className="arrow"/>:''
                                          }

                                        </a>
                                          {
                                            (val.children.length>0)?
                                              <div className="sub_sub">
                                                <ul>
                                                  {
                                                    _.map(val.children, (v,k)=>{
                                                      return  <li key={k}><a href={v.link}><span>{v.title}</span></a></li>
                                                    })
                                                  }
                                                </ul>
                                              </div>:null
                                          }
                                        </li>
                                      })
                                    }
                                  </ul>
                                </div>
                                :null
                            }
                          </li>
                        )

                      })
                    }
                  </ul>
                </div>
              </div>
              :
              // web mode
              <nav>
                <div className="center">
                  <ul className="nav">
                    {
                      _.map(data, (value,key)=>{
                        return (
                          <li key={key}>
                            <a href={value.link !== ""?value.link:'#'}>
                              <span>{value.title}</span>
                              {
                                value.children.length>0?<i className="arrow"/>:''
                              }
                            </a>
                            {
                              (value.children.length>0)?
                                <div className="sub">
                                  <ul className="subnav">
                                    {
                                      _.map(value.children, (val,index)=>{

                                        return <li key={index}><a href={val.link}>
                                          <span>{val.title}</span>
                                          {
                                            val.children.length>0?<i className="arrow"/>:''
                                          }

                                        </a>
                                          {
                                            (val.children.length>0)?
                                              <div className="sub_sub">
                                                <ul>
                                                  {
                                                    _.map(val.children, (v,k)=>{
                                                      return  <li key={k}><a href={v.link}><span>{v.title}</span></a></li>
                                                    })
                                                  }
                                                </ul>
                                              </div>:null
                                          }
                                        </li>
                                      })
                                    }
                                  </ul>
                                </div>
                                :null
                            }
                          </li>
                        )

                      })
                    }
                  </ul>
                </div>
              </nav>
          }
        </>
    )
}

//Menu.propTypes = {
//    title: PropTypes.string,
//    test: PropTypes.string,
//    onClickHeader: PropTypes.func
//
//}
//Menu.defaultProps={
//    title: "Bsma",
//    test:"zura",
//    onClickHeader: (e)=>console.log(e)
//}
