import React, {useCallback, useEffect, useState} from 'react';
import {Config, Request} from "bsma/src";
import _ from 'lodash';
import Event from "../utils/eventLisener";

export const MobileMenu = ({title,test,onClickHeader, lang,id})=>{
  const [data, setData] = useState([]);
  const [menu,setMenu]= useState(0);

  useEffect(()=>{
    Request.get(Config.Web.Menu+lang+"/"+id).then(response=>{
      if(response['status']){
        setData(JSON.parse(response['data']['data']['data']))
      }else{
        setData([]);
      }
    })
  },[lang])


  useEffect(()=>{
    Event.dispatch("openMenu",menu?1:0)
  },[menu])


    return (
        <>
          <div className="mob_head">
              <div className="l_box">
                <a href="/"></a>
                <span>ბათუმის სახელმწიფო საზღვაო აკადემია</span>
              </div>
              <i className="m_nav" onClick={()=>setMenu(!menu)}></i>
          </div>
        </>
    )
}

//Menu.propTypes = {
//    title: PropTypes.string,
//    test: PropTypes.string,
//    onClickHeader: PropTypes.func
//
//}
//Menu.defaultProps={
//    title: "Bsma",
//    test:"zura",
//    onClickHeader: (e)=>console.log(e)
//}
