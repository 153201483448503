import {VideoCard} from "./VideoCard";
import React, {Component, useEffect, useState} from 'react';
import {useTranslation} from "bsma/src/lb-core/i18n/i18n";
import _ from "lodash";
import Moment from "moment";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

export const VideoComponent = ({data})=>{
  const { t, i18n } = useTranslation();
  const lang = useSelector(state=>state.Lang)
  const d = {
    "ka_01":'იანვარი',
    "ka_02":'თებერვალი',
    "ka_03":'მარტი',
    "ka_04":'აპრილი',
    "ka_05":'მაისი',
    "ka_06":'ივნისი',
    "ka_07":'ივლისი',
    "ka_08":'აგვისტო',
    "ka_09":'სექტემბერი',
    "ka_10":'ოქტომბერი',
    "ka_11":'ნოემბერი',
    "ka_12":'დეკემბერი',
    "ka_year":'წელი',

    "en_01":'January',
    "en_02":'February',
    "en_03":'March',
    "en_04":'April',
    "en_05":'May',
    "en_06":'June',
    "en_07":'July',
    "en_08":'August',
    "en_09":'September',
    "en_10":'October',
    "en_11":'November',
    "en_12":'December',
    "en_year":'year',
  };

  return  (
    <section className="video" style={{marginTop:'30px'}}>
      <h3 className="title">{t("VIDEO")}</h3>
      <div className="content">
        {
          _.map(data, (value,index)=>{
            //console.log(value)
            return  (
              <VideoCard
                key={index}
                date={Moment(value.create_date).format("DD") +' '+ d[lang+'_'+Moment(value.create_date).format("MM")] +' '+ Moment(value.create_date).format("YYYY")+' '+d[lang+'_year']}
                title= {value.title}
                video= {`//www.youtube.com/embed/${value.embed}`}
              />
            )
          })
        }
      </div>
    </section>
  )
}
