import React from  'react';
import PropTypes from 'prop-types';

export const ContactCard = (props)=>{

    return (
        <>
            <div className="callBox">
                <h4>{props.name}</h4>
                <p>{props.job}</p>
                <p>{props.email}</p>
            </div>
        </>
    )
}

ContactCard.propTypes = {
    name: PropTypes.string,
    email: PropTypes.string,
    job: PropTypes.string
}
ContactCard.defaultProps={
    name: "ზურაბ ფუტკარაძე",
    email:"ZURA.PH@GMAIL.COM",
    job: "საინჟინრო ფაკულტეტის დეკანი"
}
