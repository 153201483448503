import React, {useEffect, useState} from 'react';
import _ from 'lodash'
import {useTranslation} from "bsma/src/lb-core/i18n/i18n";
import {useSelector} from "react-redux";
import {Config, Request} from "bsma/src";

export const QuestionsComponent = ({id})=>{


  const { t, i18n } = useTranslation();
  const lang = useSelector(state=>state.Lang)
  const [data, setData] = useState([]);
  useEffect(()=>{
    Request.get(Config.Web.Faq+lang+"/7").then(response=>{
      if(response['status']){
        setData(JSON.parse(response['data']['data']['data']))
      }else{
        setData([]);
      }
    })
  },[lang])




  const [isActive, setActive] = useState("false");
  const handleToggle = (id) => {
     id = isActive === id ? '':id;
     setActive(id);
  };


  return (
    <div className="questions box">
      <h3>{t('FAQ')}</h3>
      <ul>
        {
          _.map(
            _.filter(data,(v,k)=>k<4), (value,index)=>{
              return  (
                <li className={`${isActive === index ? "active" : ""}`} key={index}>
                  <div onClick={() => handleToggle(index)}>{value.title}</div>
                  <p>{value.body}</p>
                </li>
              )
            }
          )
        }
      </ul>
      <a href="/questions" className="all_news">{t('SEE_ALL')} <i className="white_arrow"></i></a>
    </div>
  )

}

