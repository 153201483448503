import React from  'react';
import PropTypes from 'prop-types';

export const PersonalCard = (props)=>{



    return (
        <>
            <div className="callBox box">
                <div className="img">
                    <img src="assets/img/irakli.jpg"/>
                </div>
                <div className="personalInfo">
                    <h4>{props.name}</h4>
                    <p data-education>{props.education}</p>
                    <p data-email>{props.email}</p>
                </div>
            </div>
        </>
    )
}

PersonalCard.propTypes = {
    name: PropTypes.string,
    email: PropTypes.string,
    education: PropTypes.string
}
PersonalCard.defaultProps={
    name: "ირაკლი შარაბიძე",
    email:"zura.ph@gmail.com",
    education: "პროფესორი"
}
