const Event = {
  events:{},
  dispatch:function(key,data){
    if(!this.events[key]) return;
    this.events[key].forEach(callback=>callback(data))
  },
  subscribe: function (key,callback) {
    if(!this.events[key]) this.events[key] = [];
    this.events[key].push(callback);
    return this;
  },
  unsubscribe: function(key,callback){
    if(this.events[key]) delete this.events[key];
    return this;
  }
}
export default Event;
