import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Config, Request} from "bsma/src";
import {useSelector} from "react-redux";
import Event from "../utils/eventLisener";


export const PhotoCard = (props)=>{

  const lang = useSelector(state=>state.Lang);
  const getData = (id) => {
    Request.get(Config.Web.Gallery+lang+"/"+id).then(response=>{
      if(response['status']){
        Event.dispatch('getGalleryData',_.map(JSON.parse(response.data.data.pictures),e=>e.path))
      }
    })
  }

    return (
        <>
            <div className="callBox" onClick={()=>getData(props.id)}>
                <div className="img" data-date={props.date} style={{background:'url('+props.img+')'}}/>
                <p>{props.title}</p>
            </div>

        </>
    )
}

PhotoCard.propTypes = {
    date: PropTypes.string,
    title: PropTypes.string,
    img: PropTypes.string
}
PhotoCard.defaultProps={
    date: "12.06.2020",
    title:"მითითებული პირადი ნომერი უკვე არის სისტემაში ებული პირა",
    img: 'assets/img/photo.jpg'
}
