import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "bsma/src/lb-core/i18n/i18n";
import {MainSlider} from "../components/MainSlider";
import {Footer, Menu, RunnerNews, SearchComponent} from "../components";
import {MobileMenu} from "../components/MobileMenu";



export const Index = ({children}) =>{
  const lang = useSelector(state=>state.Lang);
  document.title = lang === 'ka'?'ბათუმის სახელმწიფო საზღვაო აკადემია':'Batumi State Maritime Academy';
  const { t, i18n } = useTranslation();

  if(window.location.pathname.toLocaleString().indexOf('/personal') !== -1){
    return children
  }

  return (<div>
    <MobileMenu />
    <MainSlider id={3} lang={lang}/>
    <Menu lang={lang} id={4}/>
    {
      children
    }
    <Footer lang={lang} id={5}/>
  </div>)
}
