import React, {useEffect, useState} from 'react';
import {useTranslation} from "bsma/src/lb-core/i18n/i18n";
import {useSelector} from "react-redux";
import {Config, Request} from "bsma/src";
import _ from 'lodash';
import Moment from "moment";
import news from "../assets/img/news/1234.jpg";
export const MainNews = () =>{
  const { t, i18n } = useTranslation();
  const lang = useSelector(state=>state.Lang);
  const [data, setData] = useState([]);


  useEffect(()=>{
    Request.get(Config.Web.MainNews+lang).then(response=>{
      if(response['status']){
        setData(response['data']['data']);
      }else{
        setData([]);
      }
    })
  },[lang])
  return  (
    <div className="big_news box">
      <i className="big_arrow" data-left="" />
      <div className="content">
        <div className="bn_box">
          {
            data.image?(<div className="img" style={{background:'url('+data.image+')'}} />):(<div className="img" style={{background:'url(/static/news_default.png)'}} />)
          }

          <div className="info">
            <div data-date="" dangerouslySetInnerHTML={{__html: Moment(data.create_date).format("DD MM yyyy").toString().split(' ').map((v, index) => '<span key='+index+'>'+v+'</span>').join(' ') }}/>
            <a href={'/news/'+data.id} data-title="">{data.title}</a>
            <div data-text="" dangerouslySetInnerHTML={{__html:data.short_content}}/>
          </div>
        </div>

      </div>
      <a href={'/news/'+data.id} className="all_news">{t('SEE_ALL')} <i className="white_arrow"/></a>
      <i className="big_arrow " data-right="" />
    </div>
  )


}
