import React from  'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {NewsCard} from "./NewsCard";
import Moment from "moment";
import {Link} from "react-router-dom";

export const ProjectCard = (props)=>{
  return (
    <>
      <a className="callBox" href={'/project/'+props.id}>
        <div className="img" data-date={props.title} style={{background:'url('+props.image+')'}}/>
        <p dangerouslySetInnerHTML={{__html: props.short_content}}/>
      </a>
    </>
  )
}

ProjectCard.propTypes = {
  date: PropTypes.any,
  short_content: PropTypes.any,
  title: PropTypes.any,
  image: PropTypes.any
}

ProjectCard.defaultProps={
  date: "12.06.2020",
  short_content: "ებული პირადი ნომერი უკვე არის სისტემაში ებულ",
  title:"მითითებული პირადი ნომერი უკვე არის სისტემაში ებული პირა",
  image: 'assets/img/projects.jpg'
}



/*
import React from  'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export const ProjectCard = (props)=>{

    return (
        <>
            <div className="callBox">
                <div className="img" data-date={props.date} style={{background:'url('+props.img+')'}}></div>
                <p>{props.title}</p>
            </div>
        </>
    )
}

ProjectCard.propTypes = {
    date: PropTypes.string,
    title: PropTypes.string,
    img: PropTypes.string
}
ProjectCard.defaultProps={
    date: "12.06.2020",
    title:"მითითებული პირადი ნომერი უკვე არის სისტემაში ებული პირა",
    img: 'assets/img/projects.jpg'
}
*/
