import React, {useEffect, useState} from 'react';
import {useTranslation} from "bsma/src/lb-core/i18n/i18n";
import {useSelector} from "react-redux";
import {Config, Request} from "bsma/src";
import _ from 'lodash';
import Moment from "moment";
export const RunnerNews = () =>{
  const { t, i18n } = useTranslation();
  const lang = useSelector(state=>state.Lang);
  const [data, setData] = useState([]);
  const [current,setCurrent] = useState(0);
  const [dataLen,setDataLen] = useState(0);

  useEffect(()=>{
    Request.get(Config.Web.News+lang+"?start=0&limit=3&order=DESC").then(response=>{
      if(response['status']){
        setData(response['data']['data']);
        setDataLen(_.size(response['data']['data']));
      }else{
        setData([]);
      }
    })
  },[lang])
  return  (
    <div className="newsline">
      <div data-title>{t('ADDED')}</div>
      <div className="news_wrap">
        <div className="news_inline_box" style={{marginTop: -(current * 30)+"px"}}>
          {
            _.map(data, (value,index)=>{
              return  (
                <div className="news" key={index}>
                  <div data-date>{Moment(value.create_date).format("hh:mm | DD.MM")}</div>
                  <div data-text>{value.title}</div>
                </div>
              )
            })
          }
        </div>
      </div>
      <div data-action>
        <i className="arrow" data-left={""} onClick={()=>{
          setCurrent(current-1<0 ? 0: current-1);
        }}/>
        <i className="arrow" data-right="" onClick={()=>{
          setCurrent(current+1>=(dataLen-1) ? dataLen-1 : current+1);
        }}/>
      </div>
    </div>
  )


}
