import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useTranslation ,withTranslation} from 'react-i18next';
//language files
//en
import en from "./locales/en/translation.json";
//ka
import ka from "./locales/ka/translation.json";

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'ka',
        lng: localStorage.getItem("i18nextLng") || 'ka',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translations: en,
            },
            ka: {
                translations: ka,
            }
        },
        ns: ["translations"],
        defaultNS: "translations"
    });

export default i18n;
export  {

    useTranslation,
    withTranslation,
}
