import React, {useEffect, useState} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import im1 from '../assets/img/1.png'
import im2 from '../assets/img/2.png'
import im3 from '../assets/img/3.png'
import im4 from '../assets/img/4.png'
import im5 from '../assets/img/5.png'
import {Config, Request} from "bsma/src";
import _ from 'lodash';
export const Footer = ({title,test,onClickHeader,lang,id})=>{
  const [data, setData] = useState([]);
  const [usefullLinks, setUsefullLinks] = useState([]);
  const [usefullTitle, setUsefullTitle] = useState("");
  useEffect(()=>{
    Request.get(Config.Web.Menu+lang+"/"+id).then(response=>{
      if(response['status']){
        setData(JSON.parse(response['data']['data']['data']))
      }else{
        setData([]);
      }
    })
    Request.get(Config.Web.Menu+lang+"/6").then(response=>{
      if(response['status']){
        setUsefullLinks(JSON.parse(response['data']['data']['data']))
        setUsefullTitle(response['data']['data']['title'])
      }else{
        setUsefullLinks([]);
        setUsefullTitle("");
      }
    })
  },[lang])
    return (
        <>
            <footer>
                <div className="useful_links">
                    <div className="center">
                        <h3>{usefullTitle}</h3>
                    </div>
                    <div className="ul-box">
                        <div className="center">

                            <OwlCarousel
                                className="useful_links_carousel"
                                items = {5}
                                nav
                                dots
                                loop
                                margin = {10}
                                autoWidth
                            >
                              {
                                _.map(usefullLinks, (v,k)=>{
                                  return  (
                                    <div className="item img" key={k}>
                                      <a href={v.url} target="_blank">
                                        <img src={v.image}/>
                                      </a>
                                    </div>
                                  )
                                })
                              }
                            </OwlCarousel>

                        </div>
                    </div>
                </div>

                <div className="foot_nav">
                    <div className="center">
                        <div className="nav_row">
                          {
                            (data.length>0)?
                               _.map(data, (value,key)=>{
                                return (<div className="nav_col" key={key}>
                                   <h3>{value.title}</h3>
                                  {
                                    (value.children.length>0)?
                                        _.map(value.children, (v,k)=>{
                                          return    <a href={v.link} key={k}>{v.title}</a>
                                        })
                                      :null
                                  }
                                 </div>)

                               })
                              :null
                          }
                        </div>
                    </div>
                </div>

                <div className="google_map"></div>

                <div className="foot_line">
                    <div className="center">
                        <div className="soc-icons">
                            <span>Batumi state maritime academy | 2021</span>
                          <a target='_blank' href="https://www.facebook.com/BatumiStateMaritimeAcademy" className="soc_icons" data-fb></a>
                          {/*<a href="" className="soc_icons" data-in></a>*/}
                          <a target='_blank' href="https://www.youtube.com/channel/UCk6sbd5DHJCGA-ElYOKft8Q" className="soc_icons" data-youtobe></a>
                          <a target='_blank' href="https://twitter.com/BatumiState" className="soc_icons" data-twitter></a>
                        </div>
                    </div>

                </div>

            </footer>
        </>
    )
}
