import React from  'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export const NewsCard = (props)=>{

  return (
      <>
          <div className="bn_box">
              <div className="img" style={{background:'url('+props.img+')'}}/>
              <div className="info">
                  <div data-date dangerouslySetInnerHTML={{__html: props.date}}/>
                  <div data-title>{props.title}</div>
                  <div data-text>{props.text}</div>
              </div>
          </div>
      </>
  )
}

NewsCard.propTypes = {
    date: PropTypes.any,
    title: PropTypes.any,
    text: PropTypes.any,
    img: PropTypes.string
}
NewsCard.defaultProps={
    date: null,
    title:"მითითებული პირადი ნომერი უკვე არის სისტემაში ებული პირა",
    text: "მითითებული პირადი ნომერი უკვე არის სისტემაში რეგი მითითებული პირადი ნომერი უკვე არისაში რეგი მმაში რეგი",
    img: 'assets/img/news/1234.jpg'
}
