import React from 'react';

const Home = React.lazy(() => import(("./views/Home")));
const Contact = React.lazy(() => import("./views/Contact"));
const News = React.lazy(() => import("./views/News"));
const Personal = React.lazy(() => import("./views/Personal"));
const Photo = React.lazy(() => import("./views/Photo"));
const Projects = React.lazy(() => import("./views/Projects"));
const CurrentProject = React.lazy(() => import("./views/CurrentProject"));
const Video = React.lazy(() => import("./views/Video"));
const CurrentPerson = React.lazy(() => import("./views/CurrentPerson"));
const CurrentNews = React.lazy(() => import("./views/CurrentNews"));
const Pdf = React.lazy(() => import("./views/Pdf"));
const DemoPage = React.lazy(() => import("./views/DemoPage"));
const Page = React.lazy(() => import("./views/Page"));
const Questions = React.lazy(() => import("./views/Questions"));

const routes = [
    { path: '/', exact: true,  component: Home  },
    { path: '/home', name: 'Home', component: Home },
    { path: '/contact',  exact: true, name: 'Contact', component: Contact },
    { path: '/news', exact:true,  name: 'News', component: News },
    { path: '/news/:id',  name: 'CurrentNews', component: CurrentNews },
    { path: '/personal',  name: 'Personal', component: Personal },
    { path: '/currentPerson/:id',  name: 'CurrentPerson', component: CurrentPerson },
    { path: '/photo',  name: 'Photo', component: Photo },
    { path: '/projects',  name: 'Projects', component: Projects },
    { path: '/project/:id',  name: 'CurrentProject', component: CurrentProject },
    { path: '/video',  name: 'Video', component: Video },
    { path: '/pdf',  name: 'Pdf', component: Pdf },
    { path: '/demo',  name: 'Demo', component: DemoPage },
    { path: '/page/:title',  name: 'Page', component: Page },
    { path: '/questions',  name: 'Questions', component: Questions },

];

export default routes;
